import React from "react";
import { Helmet } from 'react-helmet';
import Main from "../../layout/Main";
import NwIdea from "../../assets/imgs/nw-idea.png"
import NwTypist from "../../assets/imgs/nw-typist.png"
import NwElectronic from "../../assets/imgs/nw-electronic.png"
import NwMission from "../../assets/icons/nw-mission.svg"
import NwVission from "../../assets/icons/nw-vission.svg"
import NwGec from "../../assets/imgs/nw-gec.png"
import NwGed from "../../assets/imgs/nw-ged.png"
import NwGmnsl from "../../assets/imgs/nw-gmnsl.png"
import NwCos from "../../assets/imgs/nw-cos.png"
import NwBa from "../../assets/imgs/nw-ba.png"
import NwHc from "../../assets/imgs/nw-hc.png"
import NwMd from "../../assets/imgs/nw-md.png"
import "./styles.scss";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <Main>
      <Helmet>
        <title>About | Newworks</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full"}>
        <section className="sm:pt-32 pt-24 container">
          <div className="flex flex-wrap">
            <div className="w-full md:my-10 mt-10 mb-4">
              <h5 className="font-normal tracking-[8px] md:text-body-lg text-body-md">ABOUT US</h5>
            </div>
            <div className="md:w-1/2 w-full md:mb-0 mb-8">
              <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xs font-bold md:pr-0 pr-10 font-euclidBold">
                <span className="text-black font-euclidBold">Dedicated to </span>
                <br className="md:block hidden font-euclidBold" />
                <span className="text-main-color font-euclidBold">Empowering</span>
                <br />
                <span className="text-black font-euclidBold">African Businesses</span><span className="text-main-color font-euclidBold">.</span>
              </h4>
            </div>
            <div className="md:w-1/2 xl:pl-[10rem] lg:pl-[5rem] md:pl-[0rem] w-full">
              <p>Newworks Solution is a prominent IT organisation dedicated to empowering African businesses with seamless technological solutions to enhance operational efficiency. <br /><br /> We specialise in providing cutting-edge IT solutions tailored to the unique needs of businesses in Africa. With our expertise, we enable organisations to leverage technology effectively, streamline their operations, and achieve success in today’s competitive landscape.</p>
            </div>
          </div>
        </section>
        <section className="bg-black md:h-[34rem] h-[44rem] mt-20">
          <div className="container h-full">
            <div className="flex md:flex-row flex-col flex-col-reverse md:flex-wrap w-full md:h-full">
              <div className="md:w-1/2 w-full flex justify-center md:h-full flex-col md:mt-0 mt-[5rem]">
                <h4 className="xl:text-heading-xl xl:font-medium lg:text-heading-lg lg:font-medium md:text-heading-sm md:font-medium text-heading-xs font-medium">
                  <span className="text-white font-euclidBold">Who</span>
                  <span className="text-main-color font-euclidBold"> We Are</span>
                </h4>
                <p className="text-white xl:pr-[9rem] lg:pr-[7rem] md:pr-[5rem] pr-[3rem] mt-5">Newworks Solution is a reliable organisation that offers state-of-the-art technology solutions for government services, businesses and institutions, for increased efficiency, seamless operations, and transformative growth.</p>
              </div>
              <div className="md:w-1/2 w-full flex md:justify-end justify-center md:h-full overflow-hidden">
                <img src={NwIdea} alt="NwIdea" className="md:h-full h-[20rem] hover:scale-150 duration-300 transition-all cursor-pointer" />
              </div>
            </div>
          </div>
        </section>
        <section className="xl:h-[56rem] lg:h-[46rem] h-[36rem] mt-20 relative">
          <div className="container h-full">
            <div className="flex flex-wrap w-full h-full">
              <div className="md:w-1/2 w-full flex justify-center h-full flex-col md:mt-0 mt-[26rem]">
                <h4 className="xl:text-heading-xl xl:font-medium lg:text-heading-lg lg:font-medium md:text-heading-sm md:font-medium text-heading-xs font-medium">
                  <span className="text-black font-euclidBold">What</span>
                  <span className="text-main-color font-euclidBold"> We Do</span>
                </h4>
                <p className="text-black xl:pr-[16rem] lg:pr-[10rem] md:pr-[6rem] pr-[4rem] mt-5">Using the latest technologies, we create cost efficient technological solutions for government institutions and organisations in different industries. We design and set up the necessary systems, support and maintenance for your business needs.</p>
              </div>
            </div>
          </div>
          <img src={NwTypist} alt="NwTypist" className="absolute h-full top-0 right-0" />
        </section>
        <section className="bg-black md:mt-0 mt-[20rem]">
          <div className="container h-full">
            <div className="flex flex-wrap w-full h-full">
              <div className="md:w-1/2 w-full flex flex-col justify-center xl:pr-[10rem] md:pr-[5rem] md:py-24 pt-24 pb-32">
                <div>
                  <img src={NwMission} alt="NwMission" className="h-20" />
                </div>
                <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xs font-bold text-white mt-10 font-euclidBold">
                  Our Mission
                </h4>
                <p className="text-white mt-6">To become the primary technology provider for businesses, government, education, and healthcare solutions in local, regional, and global markets.</p>

                <div className="mt-20 ">
                  <img src={NwVission} alt="NwVission" className="h-20" />
                </div>
                <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xs font-bold text-white mt-10 font-euclidBold">
                  Our Vision
                </h4>
                <p className="text-white mt-6">To harness and promote technology as the leading tool for growth and development for a better future for African businesses, governments and institutions.</p>
                <div className="mt-10">
                
                  <Link to="https://drive.google.com/file/d/1xSiqy--kZTaEE35vW79lRgtnIGthBlN4/view?usp=share_link" target="_blank" className=""  >
                  <span className="text-white">Download our Corporate profile</span>
                  </Link>
                </div>
              </div>
              <div className="md:w-1/2 w-full md:block hidden">
                <img src={NwElectronic} alt="NwElectronic" className="h-full" />
              </div>
            </div>
          </div>
        </section>
        <section className="mt-20 relative mb-20">
          <div className="container h-full border-b-[0.5px] border-b-[#bababa] pb-20">
            <div className="w-full h-full">
              <div className="w-full flex h-full flex-col">
                <h4 className="xl:text-heading-xl xl:font-medium lg:text-heading-lg lg:font-medium md:text-heading-sm md:font-medium text-heading-xs font-medium">
                  <span className="text-black font-euclidBold">Meet the</span>
                  <br />
                  <span className="  text-main-color font-euclidBold">Leadership Team</span>
                </h4>
              </div>
              <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 mt-16">
                <div className="mb-10">
                  <img src={NwGec} alt="NwGec" className="mb-4" />
                  <p className="text-body-xl font-euclidBold leading-[16px]">Mahmood Ahmadu</p>
                  <p className="text-body-md font-euclidLight">Group Executive Chairman</p>
                </div>
                <div className="mb-10">
                  <img src={NwGed} alt="NwGed" className="mb-4" />
                  <p className="text-body-xl font-euclidBold leading-[16px]">Anthony Nwachukwu</p>
                  <p className="text-body-md font-euclidLight">Group Executive Director</p>
                </div>
                <div className="mb-10">
                  <img src={NwCos} alt="NwCos" className="mb-4" />
                  <p className="text-body-xl font-euclidBold leading-[16px]">Kassim Gidado</p>
                  <p className="text-body-md font-euclidLight">Chief of Staff</p>
                </div>
                <div className="lg:block hidden"></div>
                <div className="lg:block hidden"></div>
                {/*<div className="mb-10">
                  <img src={NwGmnsl} alt="NwGmnsl" className="mb-4" />
                  <p className="text-body-xl font-euclidBold leading-[16px]">Tahir Bamanga</p>
                  <p className="text-body-md font-euclidLight leading-[18px] mt-[4px]">General Manager<br />Newworks Solution Limited</p>
                </div>*/}
                
                <div className="mb-10">
                  <img src={NwBa} alt="NwBa" className="mb-4" />
                  <p className="text-body-xl font-euclidBold leading-[16px]">Abi Haruna</p>
                  <p className="text-body-md font-euclidLight leading-[18px] mt-[4px]">Board Advisor, General<br />Counsel & Company<br />Secretary</p>
                </div>
                {/*<div className="mb-10">
                  <img src={NwHc} alt="NwHc" className="mb-4" />
                  <p className="text-body-xl font-euclidBold leading-[16px]">Imade George-Ogbonna</p>
                  <p className="text-body-md font-euclidLight leading-[18px] mt-[4px]">Head of Compliance<br />and Risk Management</p>
                </div>*/}
                <div className="mb-10">
                  <img src={NwMd} alt="NwHi" className="mb-4" />
                  <p className="text-body-xl font-euclidBold leading-[16px]">Abba Abdullahi Adams</p>
                  <p className="text-body-md font-euclidLight">Managing Director<br/>  Newworks Solution Limited </p>
                </div>
                <div className="lg:block hidden"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default About;
